import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import { MdLocationOn, MdPhone, MdEmail } from "react-icons/md";
import { GatsbyImage } from "gatsby-plugin-image";
import { Button, Card } from "react-bootstrap";
import parse from "html-react-parser";

const BlogPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        sort: { fields: dateGmt, order: DESC }
        filter: { title: { ne: "Website Images" } }
      ) {
        nodes {
          slug
          excerpt
          title
          dateGmt
          blogFields {
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
                altText
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "blog" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        blogPageFields {
          blogPageIntroSection {
            heading
            subheading
          }
          blogPageBottomImages {
            blogBottomImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            blogBottomImage2 {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, blogPageFields },
  } = data;

  const { blogPageIntroSection, blogPageBottomImages } = blogPageFields;
  const siteUrl = data.site.siteMetadata.siteUrl;
 
  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Blog",
        item: {
          url: `${siteUrl}/blog`,
          id: `${siteUrl}/blog`,
        },
      },
    ],
  };

  //  const html = `<form
  //     action="https://formspree.io/f/"
  //     method="POST">
  //   <div class="form-group">
  //       <input class="form-control bg-light border-0 py-5" placeholder="Name" type="text" name="name">
  //   </div>
  //   <div class="form-group">
  //       <input class="form-control bg-light border-0 py-5" placeholder="Email Address" type="email" name="_replyto">
  //   </div>
  //   <div class="form-group">
  //       <input class="form-control bg-light border-0 py-5" placeholder="Telephone Number" type="text" name="telephone">
  //   </div>
  //   <div class="form-group">
  //    <textarea class="form-control bg-light border-0 py-5" rows="3" name="message" placeholder="Message"></textarea>
  //   </div>
  //   // Send Button
  //   <div class="g-recaptcha my-3" data-sitekey=""></div>
  //   <button class="btn btn-outline-primary mt-3" id="contact-send-btn" type="submit">Send Message</button>
  //   </form>`

  return (
    <Layout>
      <Helmet>
        <script
          src="https://www.google.com/recaptcha/api.js"
          async
          defer
        ></script>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/blog`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      <div style={{ overflow: "hidden" }}>
        <section className="">
          <Container>
            <Row>
              <Col className="text-center  mb-lg-5 mt-5">
                <p className="text-text-secondary jost-bold fs-6">
                  {blogPageIntroSection?.subheading || "OUR LATEST BLOG POSTS"}
                </p>
                <h1 className=" display-4 ">
                  {blogPageIntroSection?.heading || "Blog"}
                </h1>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5 py-lg-7">
          <Container>
            <Row className="g-xl-6 g-4 h-100 ">
              {data.allWpPost.nodes.map((post) => (
                <Col style={{ minHeight: "100%" }} className="" md={6} xl={4}>
                  <Card className="h-100 position-relative">
                    <Card.Img
                      style={{ maxHeight: "254px" }}
                      as={GatsbyImage}
                      variant="top"
                      image={
                        post.blogFields.featuredImage.node.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={post.blogFields.featuredImage.node.altText}
                    />
                    <Card.Body className="p-3 pb-6">
                      <Card.Title className="text-secondary">
                        {post.title}
                      </Card.Title>
                      <Card.Text>{parse(post.excerpt)}</Card.Text>
                      <div className="position-absolute bottom-0 start-0 p-3">
                        <Button
                          as={Link}
                          to={`/blog/${post.slug}`}
                          className="px-3  w-100 w-md-auto text-white mt-1"
                          variant="primary"
                        >
                          {" "}
                          Read More
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        {blogPageBottomImages && (
          <section className="d-lg-none">
            <Container fluid className="px-0 mx-0">
              <Row>
                {blogPageBottomImages.blogBottomImage && (
                  <Col xs={12}>
                    <GatsbyImage
                      className="w-100"
                      image={
                        blogPageBottomImages.blogBottomImage.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={blogPageBottomImages.blogBottomImage.node?.altText}
                    />
                  </Col>
                )}
                {blogPageBottomImages.blogBottomImage2 && (
                  <Col xs={12}>
                    <GatsbyImage
                      className="w-100"
                      image={
                        blogPageBottomImages.blogBottomImage2.node?.localFile
                          .childImageSharp.gatsbyImageData
                      }
                      alt={blogPageBottomImages.blogBottomImage2.node?.altText}
                    />
                  </Col>
                )}
              </Row>
            </Container>
          </section>
        )}
        {blogPageBottomImages && (
          <section
            style={{ height: "80vh" }}
            className="position-relative w-100 d-none d-lg-block"
          >
            {blogPageBottomImages.blogBottomImage && (
              <div className="h-100 position-absolute start-0 main-image-left">
                <GatsbyImage
                  className="h-100 w-100"
                  image={
                    blogPageBottomImages.blogBottomImage.node?.localFile
                      .childImageSharp.gatsbyImageData
                  }
                  alt={blogPageBottomImages.blogBottomImage.node?.altText}
                />
              </div>
            )}

            {blogPageBottomImages.blogBottomImage2 && (
              <div className="h-100 position-absolute end-0 main-image-right">
                <GatsbyImage
                  className="h-100 w-100"
                  image={
                    blogPageBottomImages.blogBottomImage2.node?.localFile
                      .childImageSharp.gatsbyImageData
                  }
                  alt={blogPageBottomImages.blogBottomImage2.node?.altText}
                />
              </div>
            )}
          </section>
        )}
      </div>
    </Layout>
  );
};

export default BlogPage;
